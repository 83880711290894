import React from 'react';

const AboutUs = () => (
    <div className="container">
        <div className="hr"></div>
        <div className="row">
            <div className="col-12">
                <h1 className="page-title-master">About Us</h1>
            </div>
        </div>
        <div className="page_header_full">
            <div className="page_title_header"></div>
        </div>

        <div id="float_left">	
            <div className="text_with_image_on_right_2">
                <p><strong>Trade Like a Pro with Capital Trends</strong></p>
                <p>
                    Capital Trends is your one-stop shop for mastering the art of trading. We provide a comprehensive suite of educational resources and professional-grade tools designed to empower you to trade with confidence, regardless of your experience level.
                </p>

                <p><strong>Empowering Traders of All Levels</strong></p>
                <p>
                    Our mission is to bridge the gap between theory and practice. We offer a wealth of educational resources that break down complex trading concepts into easily digestible information. This knowledge base, combined with our powerful tools, equips you with the skills and insights needed to navigate the ever-changing market landscape.
                </p>


                <p><strong>Elevate Your Trading Experience</strong></p>
                <p>
                    <ul>
                        <li>Capital Trends offers a comprehensive suite of tools to enhance your trading decisions: </li>
                        <li>Market Dashboard: Gain real-time insights into market movements with our comprehensive dashboard featuring live data and insightful analysis.</li>
                        <li>Pattern Recognition Agent: Leverage the power of artificial intelligence to identify potentially profitable trading opportunities.</li>
                        <li>Advanced Charting App: Visualize market trends and analyze patterns with pinpoint accuracy using our user-friendly charting tool.</li>
                        <li>Join the Capital Trends Community</li>
                    </ul>
                </p>

                <p>Join our vibrant trading community and embark on a journey toward successful trading. With Capital Trends, you'll gain the knowledge, confidence, and professional tools needed to achieve your financial goals.</p>

                Founded by Parvesh Sharma, President and Founder, Capital Trends embodies a commitment to excellence and innovation in the financial industry.
                <br />
                <p>
                With headquarters in New Delhi, India, we extend our client-centric approach globally. Our client service and sales operations span New Delhi, India, and the financial hub of San Francisco, California, U.S.A.
                </p>

                <p> <strong>Start your journey today and experience the Capital Trends difference!</strong></p>

            </div>
	    </div>
    </div>
)

export default AboutUs;