import React from "react";
import Logo from '../../../assets/logo.svg';
import LeftSection from "./LeftSection";
import RightSection from "./RightSection";

const SignUp = () =>(
    <div className="container-fluid">
        <div className="row vh-100">
            <LeftSection />

            <div className="col">
                <div className="row">
                    <div className="col-12">
                        <div className="container-fluid">
                            <a className="navbar-brand float-end mt-4 me-3" href="./home">
                                <img src={Logo} alt="Capital Trends" />
                            </a>
                        </div>
                    </div>
                    <div className="col-2" />
                    <div className="col-8">
                        <div className="container-fluid m-10-percent">
                            <h2 className="login-header">Sign up</h2>
                            
                            <RightSection />

                            <div className="text-center pt-5">
                                <span>Already have an account?</span> 
                                <a href="/sign-in" className="btn btn-link primary-links">Log in</a>
                            </div>
                        </div>
                    </div>
                    <div className="col-2" />
                </div>
            </div>
        </div>
    </div>
)   

export default SignUp;