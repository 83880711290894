import React from "react";
import facebook from '../../../assets/facebook.svg';
import youtube from '../../../assets/youtube.svg';
import twitter from '../../../assets/twitter.svg';
import linkedin from '../../../assets/linkedin.svg';

export const CopyRightSection = () => (
  <div className="container">
    <div className="copyright">
      <div className="copyright-text float-start">
        &copy; Copyright 2024 Capital Trends. All rights reserved.
      </div>

      <div className="social-icons float-end">
        <a href="https://www.youtube.com/@GoCapitalTrends/featured" target="alt">
          <span className="px-2">
            <img src={youtube} alt="youtube"/>
          </span>
        </a>
        <a href="https://www.linkedin.com/company/capital-trends/" target="alt">
          <span className="px-2">
            <img src={linkedin} alt="linkedIn" />
          </span>
        </a>
        <a href="https://twitter.com/CapitalTrends/" target="alt">
          <span className="px-2">
            <img src={twitter} alt="twitter" />
          </span>
        </a>
        <a href="https://www.facebook.com/CapitalTrends/" target="alt">
          <span className="px-2">
            <img src={facebook} alt="facebook" />
          </span>
        </a>
      </div>
    </div>
  </div>
);