import React from "react";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
import CircularProgress from '@mui/joy/CircularProgress';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const options = {
    indexAxis: 'y',
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    scales: {
        x: {
          stacked: true,
          grid: {
            display: false,
          },
          title: 'return'
        },
        y: {
            stacked: true,
            title: 'Component',
            grid: {
                display: false,
            },
        }
    },
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
};

const RenderChart = ({ errorMsg, isLoading, topLabels, topData, day, component }) => {
  if (isLoading) {
		return (
      <div className="loader-container">
        <CircularProgress size="lg" />
      </div>
		)
	}

  if (errorMsg) {
    return (
      <Stack sx={{ width: '100%', marginTop: '10px' }} spacing={2}>
        <Alert severity="error">{errorMsg}</Alert>
      </Stack>
    )
	}

  const data = {
      labels: topLabels,
      datasets: [
          {
              data: topData,
              backgroundColor:["#208ec9", "#c120c9", "#539412", "#f09d0e", "#b3a792","#4b3c4d", "#db2923", "#e6b5dc"]
          }
      ]
  }

  const chartOptions = {
      ...options,
      plugins: {
        legend: {
          display: false,
        },
        title: {
          display: true,
          text: `NSE: ${day} performance of ${component} components.`,
        },
      },
  };

  return (
    <div className="chart-container">
      <Bar 
          options={chartOptions} 
          data={data} 
      />
    </div>
  )
}

export default RenderChart;