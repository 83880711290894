import React, { useState } from "react";
import CircularProgress from '@mui/joy/CircularProgress';
import { useParams, useNavigate } from 'react-router-dom';
import axios from "axios";
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import { BASE_URL } from '../../../constant/index';

const CreatePassword = () => {
    const [newPassword, setNewPassword] = useState('');
    const [reEnterPassword, setReEnterPassword] = useState('');
    const [errorMsg, setErrorMsg] = useState('');
    const [successMsg, setSuccessMsg] = useState('');
    const [loading, setLoading] = useState(false);
    const { uniqueId } = useParams();
    const navigate = useNavigate();

    const handleNewPasswordChange = (e) => {
        setNewPassword(e.target.value);
        setErrorMsg(''); // Clear error message when user starts typing
    };

    const handleReEnterPasswordChange = (e) => {
        setReEnterPassword(e.target.value);
        setErrorMsg(''); // Clear error message when user starts typing
    };

    const forgotPwdAction = async (e) => {
        e.preventDefault();

        if (newPassword !== reEnterPassword) {
            setErrorMsg('Passwords do not match.');
            return;
        }

        if (!newPassword || !reEnterPassword) {
            setErrorMsg('Both fields are required.');
            return;
        }

        setLoading(true);
        setErrorMsg('');
        setSuccessMsg('');

        try {
            const response = await axios.post(BASE_URL+'/createPassword', { 
                newPassword: newPassword,
                uniqueId
            });
            if (response.status===200) {
                setNewPassword('');
                setReEnterPassword('');
                navigate('/sign-in', {
                    state: {
                        msg : response.data.message
                    }
                });
            } else {
                setErrorMsg(response.data.message || 'An error occurred.');
            }
        } catch (error) {
            setErrorMsg(error.response?.data?.message || 'An error occurred while updating the password.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <div className="container">
                <div className="hr" />
                <div className="row">
                    <div className="col-12">
                        <h1 className="page-title-master">Set New Password</h1>
                    </div>
                </div>
            </div>
            <div className="container" style={{ height: '500px' }}>
                {errorMsg && (
                    <Stack sx={{ width: '100%', marginTop: '10px', marginBottom: '10px' }} spacing={2}>
                        <Alert severity="error">{errorMsg}</Alert>
                    </Stack>
                )}

                {successMsg && (
                    <Stack sx={{ width: '100%', marginTop: '10px', marginBottom: '10px' }} spacing={2}>
                        <Alert severity="success">{successMsg}</Alert>
                    </Stack>
                )}

                <div className="row">
                    <div className="col-6">
                        <div className="sign_text mb-3">Please enter a new password.</div>
                        <form onSubmit={forgotPwdAction}>
                            <div className="mb-3">
                                <input
                                    type="password"
                                    className="form-control"
                                    placeholder="New Password"
                                    value={newPassword}
                                    onChange={handleNewPasswordChange}
                                />
                            </div>
                            <div className="mb-3">
                                <input
                                    type="password"
                                    className="form-control"
                                    placeholder="Re-enter Password"
                                    value={reEnterPassword}
                                    onChange={handleReEnterPasswordChange}
                                />
                            </div>
                            <button type="submit" className="btn btn-primary" disabled={loading}>
                                {loading ? <CircularProgress size="1rem" /> : 'Set Password'}
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CreatePassword;
