import React from "react";
import Avatar from '../../../assets/Avatar.png';
import Star from '../../../assets/Star.svg';

const LeftSection = () => (
    <div className="col button-cus">
        <div className="align-middle m-20-percent">
            <div id="carouselExampleIndicators" className="carousel slide">
                <div className="carousel-indicators">
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                    {/* <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button> */}
                </div>

                <div className="carousel-inner">
                    <div className="carousel-item testimonial active">
                        <div className="row justify-content-md-center">
                            <div className="col-8 text-center">
                                <div className="stars-group stars-layout py-5">
                                    <span>
                                        <img src={Star} alt="Rating" />
                                    </span>
                                    <span>
                                        <img src={Star} alt="Rating" />
                                    </span>
                                    <span>
                                        <img src={Star} alt="Rating" />
                                    </span>
                                    <span>
                                        <img src={Star} alt="Rating" />
                                    </span>
                                    <span>
                                        <img src={Star} alt="Rating" />
                                    </span>
                                </div>
                                <div>
                                    <p>I've been using CapitalTrends for stock trading for a few months now, and I've been very impressed with their service. The platform is easy to use, and the fees are very reasonable. I've also found the customer support to be excellent.</p>  
                                    <div className="img">
                                        <img alt="Avatar" className="avatar" src={Avatar} />
                                    </div>
                                    <h2>Ramya Mishra</h2>
                                    <h3>Bhopal, Madhya Pradesh</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
);

export default LeftSection;