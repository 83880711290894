import React, { useState } from "react";
import CircularProgress from '@mui/joy/CircularProgress';
import axios from "axios";
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import { BASE_URL } from '../../../constant/index';
import { useAuth } from "../../../context/AuthProvider";

const UpdateProfile = () => {
    const { userEmail, userMobile, userName, token } = useAuth();
    console.log(userName);
    const [ isLoading, setLoading ] = useState(false);
	const [ errorMsg, setErrorMsg ] = useState('');
    const [ successMsg, setSuccessMsg ] = useState('');
    const [ input, setInput ] = useState({
        name: userName? userName: "",
        email: userEmail? userEmail: "",
        mobile: userMobile? userMobile: "",
    });

    const updateProfileAction = async (e) => {
        e.preventDefault();
        setSuccessMsg('');
        setErrorMsg('');
        if(input.email && input.mobile) {
            setLoading(true);
            try {
                const { data, status } = await axios.post(BASE_URL+'/updateUserProfile', { input }, {
                    headers: {
                        "Authorization": `Bearer ${token}`
                    }
                });
                if(status === 200 && data?.msg) {
                    setSuccessMsg(data.msg);
                }
                setLoading(false);
            } catch(err) {
			    setErrorMsg(err?.response?.data?.msg);
			    setLoading(false);
		    }
        }
    }

    const onNameChange = (event) => {
        setInput(prevState => ({
            ...prevState,
            name: event.target.value,
        }));
    }

    const onEmailChange = (event) => {
        setInput(prevState => ({
            ...prevState,
            email: event.target.value,
        }));
    }

    const onMobileChange = (event) => {
        setInput(prevState => ({
            ...prevState,
            mobile: event.target.value,
        }));
    }

    return (
        <>
            <div className="container">
                <div className="hr" />
                <div className="row">
                    <div className="col-12">
                        <h1 className="page-title-master">Update Profile</h1>
                    </div>
                </div>
            </div>
            <div className="container" style={{ height: '500px' }}>
                {
                    errorMsg && (
                        <Stack sx={{ width: '100%', marginTop: '10px', marginBottom: '10px' }} spacing={2}>
                            <Alert severity="error">{errorMsg}</Alert>
                        </Stack>
                    )
                }

                {
                    successMsg && (
                        <Stack sx={{ width: '100%', marginTop: '10px', marginBottom: '10px' }} spacing={2}>
                            <Alert severity="success">{successMsg}</Alert>
                        </Stack>
                    )
                }

                <div className="row">
                    <div className="col-6">
                        <form onSubmit={updateProfileAction}>
                            <div className="mb-3">
                                <label htmlFor="Email" className="form-label">Name</label>
                                <input 
                                    name="name" 
                                    id="reqd_name" 
                                    type="text" 
                                    className="form-control" 
                                    aria-describedby="user name" 
                                    onChange={onNameChange}
                                    placeholder="Enter your name"
                                    value={input.name}
                                    required
                                />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="Email" className="form-label">Email</label>
                                <input 
                                    name="email" 
                                    id="reqd_Email" 
                                    type="email" 
                                    className="form-control" 
                                    aria-describedby="emailHelp" 
                                    onChange={onEmailChange}
                                    placeholder="Enter your registered email or mobile"
                                    value={input.email}
                                    readOnly
                                />
                                <div id="emailHelp" className="form-text mb-3">If you want to change your email address, please do a new registration with your new email address or reach out to us on help@capitaltrends.in</div>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="currentPwd" className="form-label">Mobile</label>
                                <input 
                                    name="mobile" 
                                    id="mobile" 
                                    type="text" 
                                    className="form-control" 
                                    aria-describedby="mobile number" 
                                    onChange={onMobileChange}
                                    placeholder="Enter your mobile number"
                                    value={input.mobile}
                                    required
                                />
                                <div id="emailHelp" className="form-text mb-3">We'll never share your mobile number with anyone else.</div>
                            </div>
                            <div className="d-flex">
                                <button type="submit" className="btn btn-primary mt-3">{isLoading ? (<CircularProgress size="sm" />) : 'Submit'}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div> 
        </>
    );
}

export default UpdateProfile;