import React, { useState } from "react";
import CircularProgress from '@mui/joy/CircularProgress';
import axios from "axios";
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import { BASE_URL } from '../../../constant/index';

const ForgotPassword = () => {
    const [ isLoading, setLoading ] = useState(false);
	const [ errorMsg, setErrorMsg ] = useState('');
    const [ successMsg, setSuccessMsg ] = useState('');
    const [email, setEmail] = useState('');

    const forgotPwdAction = async (e) => {
        e.preventDefault();
        setSuccessMsg('');
        setErrorMsg('');
        if(email) {
            setLoading(true);
            try {
                const { data } = await axios.post(BASE_URL+'/forgotPassword', { userEmail: email });
                setSuccessMsg(data.msg);
                setLoading(false);
            } catch(err) {
			    setErrorMsg('Server encountered an issue, please try later. If issue still persist, please reach out on help@capitaltrends.in');
			    setLoading(false);
		    }
        }
    }

    const onEmailChange = (event) => {
        setEmail(event.target.value);
    }

    return (
        <>
            <div className="container">
                <div className="hr" />
                <div className="row">
                    <div className="col-12">
                        <h1 className="page-title-master">Forgot Password</h1>
                    </div>
                </div>
            </div>
            <div className="container" style={{ height: '500px' }}>
                {
                    errorMsg && (
                        <Stack sx={{ width: '100%', marginTop: '10px', marginBottom: '10px' }} spacing={2}>
                            <Alert severity="error">{errorMsg}</Alert>
                        </Stack>
                    )
                }

                {
                    successMsg && (
                        <Stack sx={{ width: '100%', marginTop: '10px', marginBottom: '10px' }} spacing={2}>
                            <Alert severity="success">{successMsg}</Alert>
                        </Stack>
                    )
                }

                <div className="row">
                    <div className="col-6">
                        <div className="sign_text mb-3">Please provide your email address associated with Capital Trends account and we will email you instructions to reset your password.</div>
                        <form onSubmit={forgotPwdAction}>
                            <div className="mb-3">
                                <label htmlFor="Email" className="form-label">Email</label>
                                <input 
                                    name="email" 
                                    id="reqd_Email" 
                                    type="email" 
                                    className="form-control" 
                                    aria-describedby="emailHelp" 
                                    onChange={onEmailChange}
                                    placeholder="Enter your email"
                                    required
                                />
                                <div id="emailHelp" className="form-text mb-3">We'll never share your email with anyone else.</div>
                            </div>
                            <div className="d-flex">
                                <button type="submit" className="btn btn-primary mt-3">{isLoading ? (<CircularProgress size="sm" />) : 'Submit'}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div> 
        </>
    );
}

export default ForgotPassword;