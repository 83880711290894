import React from "react";
import Box from '@mui/material/Box';
import { DataGrid, GridToolbarQuickFilter, GridLogicOperator } from '@mui/x-data-grid';
import CircularProgress from '@mui/joy/CircularProgress';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';

const RenderTable = ({ errorMsg, isLoading, patternData, columns, checkboxesState }) => {
    if (isLoading) {
		return (
      <div className="loader-container">
        <CircularProgress size="lg" />
      </div>
		)
	}

    if (errorMsg) {
        return (
          <Stack sx={{ width: '100%', marginTop: '10px' }} spacing={2}>
            <Alert severity="error">{errorMsg}</Alert>
          </Stack>
        )
    }

    const formattedData = patternData.map((item, index) => ({ ...item, id: index + 1 }))

    const filterBySectorCode = (sectorCode) => {
        return formattedData.filter(item => {
            // Split the INDEX_SECTOR_CODE string into an array of codes
            const sectorCodes = item.INDEX_SECTOR_CODE.split(',').map(code => code.trim());
            
            // Check if the sectorCode exists in the array
            return sectorCodes.includes(sectorCode);
        });
    }

    const Nifty50Data = filterBySectorCode("Nifty 50");
    const Nifty100Data = filterBySectorCode("Nifty 100");

    const { checkboxAll, checkboxNse50, checkboxNse100 } = checkboxesState;

    let filteredData = [];
    if(checkboxAll) {
        filteredData = formattedData;
    } else if(checkboxNse100) {
        filteredData = Nifty100Data;
    } else if(checkboxNse50) {
        filteredData = Nifty50Data;
    } else {
        filteredData = formattedData;
    } 

    return (
        <div style={{ height: 650, width: '100%', marginTop: '10px' }}>
            <DataGrid
                rowHeight={25}
                disableColumnFilter
                disableColumnSelector
                disableDensitySelector
                rows={filteredData}
                columns={columns}
                localeText={{ noRowsLabel: "No Data found for selected date" }}
                initialState={{
                    ...filteredData.initialState,
                    pagination: {
                        paginationModel: { page: 0, pageSize: 25 },
                    },
                    filter: {
                        ...filteredData.initialState?.filter,
                        filterModel: {
                            items: [],
                            quickFilterLogicOperator: GridLogicOperator.And,
                        },
                    },
                }}
                slots={{ toolbar: () => (
                    <Box sx={{ p: 0.5, pb: 0 }}>
                        <GridToolbarQuickFilter />
                    </Box>
                )}}
            />
        </div>
    )
}

export default RenderTable;