import React, { useState, useEffect } from "react";
import axios from 'axios';
import { useLocation, useParams } from 'react-router-dom';
import Avatar from '../../../assets/Avatar.png';
import Logo from '../../../assets/logo.svg';
import Star from '../../../assets/Star.svg';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import { useAuth } from '../../../context/AuthProvider';
import { BASE_URL } from "../../../constant";

const SignIn = () => {
    const { uniqueId } = useParams();
	const [ errorMsg, setErrorMsg ] = useState('');
    const [ successMsg, setSuccessMsg ] = useState('');
    const [input, setInput] = useState({
        userInput: "",
        password: "",
    });

    const auth = useAuth();
    const { state } = useLocation();

    const checkId = async () => {
        try {
            const response = await axios.post(BASE_URL+'/activateUser', { uniqueId });
            setSuccessMsg(response?.data?.msg);
        }catch(err) {
            const errorData = err?.response?.data;
            setErrorMsg(errorData.msg);
        }
    }

    useEffect(() => {
        if(state?.msg) setSuccessMsg(state.msg)
    }, []);
    
    useEffect(() => {
        if (uniqueId) {
            checkId();
        }
    }, [uniqueId]);

    const onUserInputChange = (event) => {
        setInput(prevState => ({
            ...prevState,
            userInput: event.target.value,
        }));
    }

    const onPasswordChange = (event) => {
        setInput(prevState => ({
            ...prevState,
            password: event.target.value,
        }));
    }

    const signInAction = async (e) => {
        e.preventDefault();
        setErrorMsg('');
        if (input.userInput && input.password) {
            const response = await auth.loginAction(input);
            if(response && response?.status === 500) {
                setErrorMsg(response?.data?.msg || response?.msg);
            }
        }
    }

    return (
        <div className="container-fluid">
            <div className="row vh-100">
                <div className="col button-cus">
                    <div className="align-middle m-20-percent">
                        <div id="carouselExampleIndicators" className="carousel slide">
                            <div className="carousel-indicators">
                                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                            </div>
                            <div className="carousel-inner">
                                <div className="carousel-item testimonial active">
                                    <div className="row justify-content-md-center">
                                        <div className="col-8 text-center">
                                            <div className="stars-group stars-layout py-5">
                                                <span>
                                                    <img src={Star} alt="Rating" />
                                                </span>
                                                <span>
                                                    <img src={Star} alt="Rating" />
                                                </span>
                                                <span>
                                                    <img src={Star} alt="Rating" />
                                                </span>
                                                <span>
                                                    <img src={Star} alt="Rating" />
                                                </span>
                                                <span>
                                                    <img src={Star} alt="Rating" />
                                                </span>
                                            </div>
                                            <div>
                                                <p>I've been using CapitalTrends for stock trading for a few months now, and I've been very impressed with their service. The platform is easy to use, and the fees are very reasonable. I've also found the customer support to be excellent.</p>  
                                                <div className="img">
                                                    <img alt="Avatar" className="avatar" src={Avatar} />
                                                </div>
                                                <h2>Ramya Mishra</h2>
                                                <h3>Bhopal, Madhya Pradesh</h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span className="visually-hidden">Previous</span>
                            </button>
                            <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                <span className="visually-hidden">Next</span>
                            </button> */}
                        </div>
                    </div>
                </div>

                <div className="col">
                    <div className="row">
                        <div className="col-12">
                            <div className="container-fluid">
                                <a className="navbar-brand float-end mt-4 me-3" href="/home">
                                    <img src={Logo} alt="Capital Trends" />
                                </a>
                            </div>
                        </div>
                        <div className="col-2" />
                        <div className="col-8">
                            <div className="container-fluid m-10-percent">
                                {
                                    successMsg && (
                                        <Stack sx={{ width: '100%', marginTop: '10px', marginBottom: '10px' }} spacing={2}>
                                            <Alert severity="success">{successMsg}</Alert>
                                        </Stack>
                                    )
                                }
                                {
                                    errorMsg && (
                                        <Stack sx={{ width: '100%', marginTop: '10px', marginBottom: '10px' }} spacing={2}>
                                            <Alert severity="error" onClose={() => setErrorMsg('')}>{errorMsg}</Alert>
                                        </Stack>
                                    )
                                }
                                <h2 className="login-header">Welcome back</h2>
                                <p>Welcome back! Please enter your details.</p>
                                <form onSubmit={signInAction}>
                                    <div className="mb-3 mt-5 input-group-lg">
                                        <label htmlFor="email-or-mobile" className="form-label">Email/Mobile</label>
                                        <input name="email" type="text" className="form-control" id="reqdEmail" placeholder="Enter your email or mobile" onChange={onUserInputChange} required />
                                    </div>   

                                    <div className="mb-3 input-group-lg">
                                        <label htmlFor="password" className="form-label">Password</label>
                                        <input id="reqdPassword" name="password" type="password" className="form-control" placeholder="Enter your password" onChange={onPasswordChange} required />
                                    </div>

                                    {/* <div className="form-check float-start">
                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                        <label className="form-check-label" htmlFor="flexCheckDefault">
                                            Remember for 30 days
                                        </label>
                                    </div> */}

                                    <div className="float-end forgot-password">
                                        <a href="/forgot-password">Forgot password</a>
                                    </div>

                                    <div className="d-grid gap-3 mt-5 pt-3">
                                        <button type="submit" className="btn btn-primary button-cus btn-lg">Sign in</button>
                                        {/* <button class="btn btn-outline-secondary btn-lg" type="button"><img src="images/google-icon.png" width="22" alt="Google"></a>&nbsp; Sign in with Google</button> */}
                                    </div>
                                </form>
                                <div className="text-center pt-5">
                                    <span>Don't have an account?</span> 
                                    <a href="/sign-up" className="btn btn-link primary-links">Register</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-2" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SignIn;