import React from 'react';
import useScript from '../../../hooks/useScript';

const CoachingHome = () => {
    useScript('https://assets.calendly.com/assets/external/widget.js');
    return (
        <div className="container">
            <div className="hr"></div>
            <div className="row mb-5">
                <div className="col-12">
                    <h1 className="page-title-master">1:1 Coaching</h1>
                    <p>Unlock Your Trading Potential with Personalized Guidance! Our 1:1 coaching sessions connect you directly with a seasoned stock market expert who will provide tailored guidance, answer your burning questions, and empower you with actionable steps to kickstart your trading journey. Whether you're a beginner looking to navigate the markets or an experienced trader seeking advanced strategies, our coaching will help you achieve your financial goals. Sign up now to take the first step towards trading success!</p>
                </div>
                <div className="col-12">
                    <div className="calendly-inline-widget" data-url="https://calendly.com/help-capitaltrends/30min?primary_color=444ce7" style={{ minWidth: '320px', height: '700px' }}></div>
                </div>
            </div>
        </div>
    );
}

export default CoachingHome;