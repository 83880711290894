import React, { useState } from 'react';
import CircularProgress from '@mui/joy/CircularProgress';
import axios from "axios";
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import contact from '../../../assets/contact.png';
import { BASE_URL } from '../../../constant/index';
import { useAuth } from "../../../context/AuthProvider";

const ContactUs = () => {
    const { userEmail, userMobile, userName } = useAuth();
    const [ isLoading, setLoading ] = useState(false);
	const [ errorMsg, setErrorMsg ] = useState('');
    const [ successMsg, setSuccessMsg ] = useState('');
    const [input, setInput] = useState({
        userName: userName? userName: '',
        userEmail: userEmail? userEmail: '',
        userPhone: userMobile? userMobile: '',
        userFeedback: "",
    });

    const contactAction = async (e) => {
        e.preventDefault();
        setSuccessMsg('');
        setErrorMsg('');
        if (input.userName && input.userEmail && input.userPhone && input.userFeedback) {
            setLoading(true);
            try {
                const { data, status } = await axios.post(BASE_URL+'/contactUs', { input });
                if(status === 200 && data?.msg) {
                    setSuccessMsg(data.msg);
                }
            } catch(err) {
			    setErrorMsg('Server encountered an issue, please try later. If issue still persist, please reach out on help@capitaltrends.in');
			    setLoading(false);
		    }
        }
    }

    const onNameChange = (event) => {
        setInput(prevState => ({
            ...prevState,
            userName: event.target.value,
        }));
    }

    const onEmailChange = (event) => {
        setInput(prevState => ({
            ...prevState,
            userEmail: event.target.value,
        }));
    }

    const onPhoneChange = (event) => {
        setInput(prevState => ({
            ...prevState,
            userPhone: event.target.value,
        }));
    }

    const onfeedbackChange = (event) => {
        setInput(prevState => ({
            ...prevState,
            userFeedback: event.target.value,
        }));
    }

    return (
        <>
            <div className="container">
                <div className="hr"></div>
                <div className="row">
                    <div className="col-12">
                        <h1 className="page-title-master">Contact Us</h1>
                    </div>
                </div>
            </div>
            
            <div className="container">
                {
                    errorMsg && (
                        <Stack sx={{ width: '100%', marginTop: '10px', marginBottom: '10px' }} spacing={2}>
                            <Alert severity="error">{errorMsg}</Alert>
                        </Stack>
                    )
                }

                {
                    successMsg && (
                        <Stack sx={{ width: '100%', marginTop: '10px', marginBottom: '10px' }} spacing={2}>
                            <Alert severity="success">{successMsg}</Alert>
                        </Stack>
                    )
                }

                <div className="row">
                    <div className="col-6">
                        <form onSubmit={contactAction} >
                            <div className="mb-3">
                                <label htmlFor="name" className="form-label">Name</label>
                                <input 
                                    type="name" 
                                    className="form-control" 
                                    id="name" 
                                    aria-describedby="Name" 
                                    onChange={onNameChange}
                                    value={input.userName} 
                                    placeholder="Enter your Name" 
                                    required 
                                />
                            </div>

                            <div className="mb-3">
                                <label htmlFor="email" className="form-label">Email</label>
                                <input 
                                    type="email" 
                                    className="form-control" 
                                    id="email" 
                                    aria-describedby="email" 
                                    placeholder="Enter your email" 
                                    value={input.userEmail}
                                    onChange={onEmailChange} 
                                    required 
                                />
                                <div id="emailHelp" className="form-text">We'll never share your email with anyone else.</div>
                            </div>

                            <div className="input-group mb-3">
                                <label htmlFor="basic-url" className="form-label">Phone</label>
                                <div className="input-group">
                                    <span className="input-group-text" id="basic-addon1">+91</span>
                                    <input 
                                        type="text" 
                                        className="form-control" 
                                        id="basic-url" 
                                        placeholder="Enter your mobile number" 
                                        aria-label="mobile" 
                                        aria-describedby="basic-addon1"
                                        value={input.userPhone} 
                                        onChange={onPhoneChange} 
                                        required 
                                    />
                                </div>
                            </div>

                            <div className="mb-3">
                                <label htmlFor="feedback" className="form-label">Request/Feedback</label>
                                <textarea className="form-control" id="feedback" rows="3" onChange={onfeedbackChange} required></textarea>
                            </div>

                            <div className="d-flex">
                                <button type="submit" className="btn btn-primary mb-4">{isLoading ? (<CircularProgress size="sm" />) : 'Submit'}</button>
                            </div>
                        </form>
                    </div>

                    <div className="col-6">
                        <div className="p-5">
                            <img alt="Contact Us" className="d-block w-100" src={contact} />
                        </div>
                    </div>
                </div>
            </div>    
        </>
    );
}

export default ContactUs;