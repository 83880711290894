import React, { useState } from "react";
import CircularProgress from '@mui/joy/CircularProgress';
import axios from "axios";
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import { BASE_URL } from '../../../constant/index';
import { useAuth } from "../../../context/AuthProvider";

const ChangePassword = () => {
    const { userEmail, token } = useAuth();
    const [ isLoading, setLoading ] = useState(false);
	const [ errorMsg, setErrorMsg ] = useState('');
    const [ successMsg, setSuccessMsg ] = useState('');
    const [ input, setInput ] = useState({
        email: userEmail? userEmail: "",
        currentPwd: "",
        newPwd: "",
    });

    const changePwdAction = async (e) => {
        e.preventDefault();
        setSuccessMsg('');
        setErrorMsg('');
        if(input.email && input.currentPwd && input.newPwd) {
            setLoading(true);
            try {
                const { data, status } = await axios.post(BASE_URL+'/changePassword', { input }, {
                    headers: {
                        "Authorization": `Bearer ${token}`
                    }
                });
                if(status === 200 && data?.msg) {
                    setSuccessMsg(data.msg);
                }
                setLoading(false);
            } catch(err) {
			    setErrorMsg(err?.response?.data?.msg);
			    setLoading(false);
		    }
        }
    }

    const onEmailChange = (event) => {
        setInput(prevState => ({
            ...prevState,
            email: event.target.value,
        }));
    }

    const onCurrentPwdChange = (event) => {
        setInput(prevState => ({
            ...prevState,
            currentPwd: event.target.value,
        }));
    }

    const onNewPwdChange = (event) => {
        setInput(prevState => ({
            ...prevState,
            newPwd: event.target.value,
        }));
    }

    return (
        <>
            <div className="container">
                <div className="hr" />
                <div className="row">
                    <div className="col-12">
                        <h1 className="page-title-master">Change Password</h1>
                    </div>
                </div>
            </div>
            <div className="container" style={{ height: '500px' }}>
                {
                    errorMsg && (
                        <Stack sx={{ width: '100%', marginTop: '10px', marginBottom: '10px' }} spacing={2}>
                            <Alert severity="error">{errorMsg}</Alert>
                        </Stack>
                    )
                }

                {
                    successMsg && (
                        <Stack sx={{ width: '100%', marginTop: '10px', marginBottom: '10px' }} spacing={2}>
                            <Alert severity="success">{successMsg}</Alert>
                        </Stack>
                    )
                }

                <div className="row">
                    <div className="col-6">
                        <form onSubmit={changePwdAction}>
                            <div className="mb-3">
                                <label htmlFor="Email" className="form-label">Email</label>
                                <input 
                                    name="email" 
                                    id="reqd_Email" 
                                    type="email" 
                                    className="form-control" 
                                    aria-describedby="emailHelp" 
                                    onChange={onEmailChange}
                                    placeholder="Enter your registered email or mobile"
                                    value={input.email}
                                    required
                                />
                                <div id="emailHelp" className="form-text mb-3">We'll never share your email with anyone else.</div>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="currentPwd" className="form-label">Current Password</label>
                                <input 
                                    name="currentPwd" 
                                    id="currentPwd" 
                                    type="password" 
                                    className="form-control" 
                                    aria-describedby="current password" 
                                    onChange={onCurrentPwdChange}
                                    placeholder="Enter your current Password"
                                    required
                                />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="newPwd" className="form-label">New Password</label>
                                <input 
                                    name="newPwd" 
                                    id="newPwd" 
                                    type="password" 
                                    className="form-control" 
                                    aria-describedby="new password" 
                                    onChange={onNewPwdChange}
                                    placeholder="Enter your new Password"
                                    required
                                />
                            </div>
                            <div className="d-flex">
                                <button type="submit" className="btn btn-primary mt-3">{isLoading ? (<CircularProgress size="sm" />) : 'Submit'}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div> 
        </>
    );
}

export default ChangePassword;