import React from "react";
import PropTypes from "prop-types";
import { MemoizedFeatureTabs } from './FeatureTabs';
import { IMAGE_ALIGN_LEFT, IMAGE_ALIGN_RIGHT } from '../../../../constant';

const FeaturesSection = ({
    heading,
    description,
    sectionOneText,
    sectionOneDesc,
    sectionSecondText,
    sectionSecondDesc,
    sectionThirdText,
    sectionthirdDesc,
    buttonTitle,
    tab1Url,
    tab2Url,
    tab3Url,
    selector1,
    selector2,
    selector3,
    align,
    redirectButtonUrl,
}) => (
  <div className="container py-5">
    <div className="row">
      <div className="col-7">
        <h1 className="section-title">{heading}</h1>
        <p className="section-support-text">{description}</p>
      </div>
      <div className="d-flex align-items-start CT-tabs">
        {
          align === IMAGE_ALIGN_LEFT && (
            <MemoizedFeatureTabs 
              tab1Url={tab1Url}
              tab2Url={tab2Url}
              tab3Url={tab3Url}
              selector1={selector1}
              selector2={selector2}
              selector3={selector3}
            />
          )
        }
        <div className="col-6">
          <div className="nav flex-column nav-pills ms-4" id="v-pills-tab" role="tablist" aria-orientation="vertical">
            <div className="nav-link active" id="v-pills-home-tab" data-bs-toggle="pill" data-bs-target={`#${selector1}`} type="button" role="tab" aria-controls="v-pills-home" aria-selected="true">
              <h2>{sectionOneText}</h2>
              <div className="textColor">{sectionOneDesc}</div>
            </div>
            <div className="nav-link" id="v-pills-profile-tab" data-bs-toggle="pill" data-bs-target={`#${selector2}`} type="button" role="tab" aria-controls="v-pills-profile" aria-selected="false">
              <h2>{sectionSecondText}</h2>
              <div className="textColor">{sectionSecondDesc}</div>
            </div>
            {
              sectionThirdText && (
                <div className="nav-link" id="v-pills-messages-tab" data-bs-toggle="pill" data-bs-target={`#${selector3}`} type="button" role="tab" aria-controls="v-pills-messages" aria-selected="false">
                  <h2>{sectionThirdText}</h2>
                  <div className="textColor">{sectionthirdDesc}</div>
                </div>
              )
            }
            
            <div className="get-started"><a href={redirectButtonUrl} className="btn btn-primary button-cus" type="submit">{buttonTitle}</a></div>
          </div>
        </div>

        {
          align === IMAGE_ALIGN_RIGHT && (
            <MemoizedFeatureTabs 
              tab1Url={tab1Url}
              tab2Url={tab2Url}
              tab3Url={tab3Url}
              selector1={selector1}
              selector2={selector2}
              selector3={selector3}
            />
          )
        }
      </div>
    </div>
  </div>
);

FeaturesSection.propTypes = {
  heading: PropTypes.string,
  description: PropTypes.string,
  sectionOneText: PropTypes.string,
  sectionOneDesc: PropTypes.string,
  sectionSecondText: PropTypes.string,
  sectionSecondDesc: PropTypes.string,
  sectionThirdText: PropTypes.string,
  sectionthirdDesc: PropTypes.string,
  buttonTitle: PropTypes.string,
  imageUrl: PropTypes.string,
  align: PropTypes.string,
  redirectButtonUrl: PropTypes.string,
}

export const MemoizedFeaturesSection = React.memo(FeaturesSection);