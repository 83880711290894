import React from 'react';
import { createRoot } from 'react-dom/client';
import CTApp from './components/CTApp';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
	<>
		<CTApp />
  	</>
);
