const PERFORMANCE_MAP = {
    '1-DAY': 'oneDayPerformance',
    '1-WEEK': 'weeklyPerformance',
    '3-MNTH': 'qtrPerformance',
    '6-MNTH': 'biannualPerformance',
    '1-YR': 'ytdPerformance',
    '3-YR': 'threeYearPerformance',
    '5-YR': 'fiveYearPerformance',
    '7-YR': 'sevenYearPerformance',
    'YTD': 'annualPerformance'
};

export const getMdbData = (arrayStockDataMaster, arrayStockMarketData, component, day) => {
    const performanceKey = PERFORMANCE_MAP[day];
    if (!performanceKey) return { topLabels: [], topData: [] };

    const symbolListFromSectorCode = arrayStockDataMaster
        .filter(master => master.hasOwnProperty(component))
        .flatMap(master => master[component])
        .flatMap(symbol => 
            arrayStockMarketData
                .filter(market => market.hasOwnProperty(symbol))
                .map(market => ({ [symbol]: market[symbol] }))
        );
    const finalArr = symbolListFromSectorCode.map(symbols => {
        const symbol = Object.keys(symbols)[0];
        return {
            label: symbol,
            data: symbols[symbol][performanceKey]
        };
    });

    finalArr.sort((a, b) => b.data - a.data);
    const topLabels = finalArr.slice(0, 8).map(item => item.label);
    const topData = finalArr.slice(0, 8).map(item => Number(item.data));

    return { topLabels, topData };
};
