import React from "react";
import PropTypes from "prop-types";

const FeatureTabs = ({
    tab1Url,
    tab2Url,
    tab3Url,
    selector1,
    selector2,
    selector3,
}) => (
    <div className="col-6">
        <div className="tab-content" id="v-pills-tabContent">
            <div className="tab-pane fade show active" id={selector1} role="tabpanel" aria-labelledby="v-pills-home-tab" tabIndex="0">
                <img src={tab1Url} className="d-block w-100" alt="..." />
            </div>
            <div className="tab-pane fade" id={selector2} role="tabpanel" aria-labelledby="v-pills-profile-tab" tabIndex="0">
                <img src={tab2Url} className="d-block w-100" alt="..." />
            </div>
            {
                tab3Url && (
                    <div className="tab-pane fade" id={selector3} role="tabpanel" aria-labelledby="v-pills-messages-tab" tabIndex="0">
                        <img src={tab3Url} className="d-block w-100" alt="..." />
                    </div>
                )
            }
        </div>
    </div>
);

FeatureTabs.propTypes = {
    tab1Url: PropTypes.string,
    tab2Url: PropTypes.string,
    tab3Url: PropTypes.string,
    selector1: PropTypes.string,
    selector2: PropTypes.string,
    selector3: PropTypes.string,
}

export const MemoizedFeatureTabs = React.memo(FeatureTabs);