import { calculateGain } from './performance';

export const transformMdbData = (data, dateObj) => {
    var currSymbol = '';
    var prevSymbol = '';
    var currentPrice = '';
    var previousYearEndPrice = '';
    var threeYearEndPrice = '';
    var fiveYearEndPrice = '';
    var sevenYearEndPrice = '';
    var lastQtrEndPrice = '';
    var lastHalfYrEndPrice = '';
    var yearEndPrice = '';
    var previousDayPrice = '';
    var lastweekEndPrice = '';
    var result = [];
    var valueDate = '';
    var closeValue = '';

    const { fiveYearDate, halfYearDate, maxDate, maxPreviousYearDate, previousDate, qtrDate,
        threeYearDate, weeklyDate, yearDate } = dateObj;

    data.forEach(key => {
        currSymbol = key.SYMBOL;
        // Reset the values when starting a new symbol
        if (currSymbol !== prevSymbol) {
            if (prevSymbol !== '') {
                var ytdPerformance = calculateGain(currentPrice, yearEndPrice);
                var qtrPerformance = calculateGain(currentPrice, lastQtrEndPrice);
                var biannualPerformance = calculateGain(currentPrice, lastHalfYrEndPrice);
                var annualPerformance = calculateGain(currentPrice, previousYearEndPrice);
                var threeYearPerformance = calculateGain(currentPrice, threeYearEndPrice);
                var fiveYearPerformance = calculateGain(currentPrice, fiveYearEndPrice);
                var sevenYearPerformance = calculateGain(currentPrice, sevenYearEndPrice);
                var weeklyPerformance = calculateGain(currentPrice, lastweekEndPrice);
                var oneDayPerformance = calculateGain(currentPrice, previousDayPrice);

                // Push the performance data for the previous symbol
                result.push({
                    [prevSymbol]: {
                        valueDate,
                        closeValue,
                        currentPrice,
                        yearEndPrice,
                        previousYearEndPrice,
                        lastQtrEndPrice,
                        threeYearEndPrice,
                        fiveYearEndPrice,
                        sevenYearEndPrice,
                        lastweekEndPrice,
                        previousDayPrice,
                        lastHalfYrEndPrice,
                        ytdPerformance,
                        qtrPerformance,
                        biannualPerformance,
                        annualPerformance,
                        threeYearPerformance,
                        fiveYearPerformance,
                        sevenYearPerformance,
                        weeklyPerformance,
                        oneDayPerformance,
                    }
                });
            }

            // Reset the historical prices for the new symbol
            previousYearEndPrice = 0;
            threeYearEndPrice = 0;
            fiveYearEndPrice = 0;
            sevenYearEndPrice = 0;
            lastQtrEndPrice = 0;
            lastHalfYrEndPrice = 0;
            yearEndPrice = 0;
            previousDayPrice = 0;
            lastweekEndPrice = 0;
        }

        // Assign the close values based on the dates
        valueDate = key.VALUE_DATE;
        closeValue = key.CLOSE_VALUE;
        if (valueDate === maxDate) currentPrice = closeValue;
        else if (valueDate === maxPreviousYearDate) previousYearEndPrice = closeValue;
        else if (valueDate === threeYearDate) threeYearEndPrice = closeValue;
        else if (valueDate === fiveYearDate) fiveYearEndPrice = closeValue;
        else if (valueDate === qtrDate) lastQtrEndPrice = closeValue;
        else if (valueDate === halfYearDate) lastHalfYrEndPrice = closeValue;
        else if (valueDate === yearDate) yearEndPrice = closeValue;
        else if (valueDate === weeklyDate) {
            if (valueDate === previousDate) {
                previousDayPrice = closeValue;
            }
            lastweekEndPrice = closeValue;
        }
        else if (valueDate === previousDate) previousDayPrice = closeValue;

        prevSymbol = currSymbol;
    });

    // Handle the last symbol outside the loop
    // if (prevSymbol !== '') {
    //     var ytdPerformance = calculateGain(currentPrice, yearEndPrice);
    //     var qtrPerformance = calculateGain(currentPrice, lastQtrEndPrice);
    //     var biannualPerformance = calculateGain(currentPrice, lastHalfYrEndPrice);
    //     var annualPerformance = calculateGain(currentPrice, previousYearEndPrice);
    //     var threeYearPerformance = calculateGain(currentPrice, threeYearEndPrice);
    //     var fiveYearPerformance = calculateGain(currentPrice, fiveYearEndPrice);
    //     var sevenYearPerformance = calculateGain(currentPrice, sevenYearEndPrice);
    //     var weeklyPerformance = calculateGain(currentPrice, lastweekEndPrice);
    //     var oneDayPerformance = calculateGain(currentPrice, previousDayPrice);

    //     result.push({
    //         [prevSymbol]: {
    //             valueDate,
    //             closeValue,
    //             currentPrice,
    //             yearEndPrice,
    //             previousYearEndPrice,
    //             lastQtrEndPrice,
    //             threeYearEndPrice,
    //             fiveYearEndPrice,
    //             sevenYearEndPrice,
    //             lastweekEndPrice,
    //             previousDayPrice,
    //             lastHalfYrEndPrice,
    //             ytdPerformance,
    //             qtrPerformance,
    //             biannualPerformance,
    //             annualPerformance,
    //             threeYearPerformance,
    //             fiveYearPerformance,
    //             sevenYearPerformance,
    //             weeklyPerformance,
    //             oneDayPerformance,
    //         }
    //     });
    // }

    return result;
};