import React, { useState } from "react";
import { useNavigate, createSearchParams } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';

import { useAuth } from "../../../context/AuthProvider";
import { transformMdbData } from '../../../utils/transformMdbData';
import { MARKET_DASHBOARD_COMPONENTS, MARKET_DASHBOARD_DAYS, BASE_URL } from '../../../constant/index';

import { getMdbData } from "../../../utils/getMdbData";
import RenderChart from "./RenderChart";

const MarketDashboard = () => {
	const navigate = useNavigate();
	const { token, userEmail } = useAuth();
	const [ isLoading, setLoading ] = useState(false);
	const [ errorMsg, setErrorMsg ] = useState('');
	const [ topLabels, setTopLabels ] = useState([]);
	const [ topData, setTopData ] = useState([]);
	const [ component, setSelectedComponents ] = useState('Nifty 50');
	const [ day, setSelectedDay ] = useState('1-DAY');
	const [ arStockDataMaster, setArStockDataMaster ] = useState([]);
	const [ arMdbData, setArMdbData ] = useState([]);

	const getMarketDashboardData = async () => {
		setLoading(true);
		try {
			const response = await fetch(BASE_URL+'/getMarketDashboardData', {
				method: "POST",
				headers: {
				  "Content-Type": "application/json",
				  "Authorization": `Bearer ${token}`
				},
			});

			const res = await response.json();
			if(res?.statusCode && res?.statusCode === 503) {
				setErrorMsg(res.msg);
			}
			if (res?.arStockDataMaster && res?.arStockDataMaster.length > 0) {
				const { arStockDataMaster, arStockMarketData, dateObj } = res;
				const getArMdbData = await transformMdbData(arStockMarketData, dateObj);
				const { topLabels, topData } = await getMdbData(arStockDataMaster, getArMdbData, component, day);
				console.log({ getArMdbData, topLabels, topData });
				setTopLabels(topLabels);
				setTopData(topData);
				setArStockDataMaster(arStockDataMaster);
				setArMdbData(getArMdbData);
			}
			setLoading(false);
		} catch(err) {
			setErrorMsg('Server encountered an issue, please try later. If issue still persist, please reach out on help@capitaltrends.in');
			setLoading(false);
		}
	}

	React.useEffect(() => {
		const params = { component, day };
		navigate({
			search: `?${createSearchParams(params)}`
		});
		getMarketDashboardData();
	}, []);

	const onComponentSelection = async (value) => {
		setTopLabels([]);
		setTopData([]);
		setSelectedComponents(value);
		const params = { component: value, day };
		const { topLabels, topData } = await getMdbData(arStockDataMaster, arMdbData, value, day);
		setTopLabels(topLabels);
		setTopData(topData);
		navigate({
			search: `?${createSearchParams(params)}`
		});
	}

	const onDaySelection = async (value) => {
		setTopLabels([]);
		setTopData([]);
		setSelectedDay(value);
		const params = { component, day: value };
		const { topLabels, topData } = await getMdbData(arStockDataMaster, arMdbData, component, value);
		setTopLabels(topLabels);
		setTopData(topData);
		navigate({
			search: `?${createSearchParams(params)}`
		});
	}

	if (!userEmail) {
		return (
			<div className="container error-block">
				<div className="hr" />
				<div className="row">
					<div className="col-12">
						<h1 className="page-title-master">Market Dashboard</h1>
					</div>

					<Stack sx={{ width: '100%' }} spacing={2}>
						<Alert severity="error">This is member-only section. Please login.</Alert>
					</Stack>
				</div>
			</div>
		)
	}

	return (
		<div className="container">
			<div className="hr" />
			<div className="row">
				<div className="col-12">
					<h1 className="page-title-master">Market Dashboard</h1>
				</div>
				<div className="col-3">
					<div className="md-links">
						{
							MARKET_DASHBOARD_COMPONENTS.map(({ key, value, label }) => (
								<button key={key} onClick={() => onComponentSelection(value)} className={"btn mdb-button-wdith " + (value === component ? 'btn-primary button-cus' : 'btn-outline-secondary')}>{label}</button>
							))
						}
					</div>
				</div>
				<div className="col-9">
					<div className="days-links">
						{
							MARKET_DASHBOARD_DAYS.map(({ key, value, label }) => (
								<button key={key} className={"btn " + (value === day ? 'btn-primary button-cus' : 'btn-outline-secondary')} onClick={() => onDaySelection(value)}>{label}</button>
							))
						}
					</div>

					<RenderChart 
						errorMsg={errorMsg}
						isLoading={isLoading} 
						topLabels={topLabels} 
						topData={topData} 
						day={day} 
						component={component} 
					/>	
				</div>
			</div>
		</div>        
	)
}

export default MarketDashboard;