import React from "react";
import { CopyRightSection } from "./CopyRightSection/CopyRightSection";
import logo from '../../assets/LogoWhite.png';

const Footer = () => (
  <footer id="footer" className="footer">
    <div className="container py-5">
      <div className="row gy-4">
        <div className="col-lg-4 col-md-12 footer-info">
          <a href="/home" className="logo d-flex align-items-center">
            <img src={logo} alt="logo" />
          </a>
          <p>Capital Trends is a brand of Pivot Point Research Private Limited, a financial services firm registered in New Delhi, India serving retail investors & financial institutions.</p>
        </div>
        <div className="col-lg-2 col-6 footer-links">
          <h4>Company</h4>
          <ul>
            <li><a href="/home">Home</a></li>
            <li><a href="/about-us">About Us</a></li>
            <li><a href="/contact-us">Contact Us</a></li>
          </ul>
        </div>

        <div className="col-lg-2 col-6 footer-links">
          <h4>Products</h4>
          <ul>
            <li><a href="/marketDashboard">Market Dashboard</a></li>
            <li><a href="/patternRecognition">Pattern Recognition</a></li>
          </ul>
        </div>
      </div>
    </div>
    <CopyRightSection />
  </footer>
);

export const MemoizedFooter = React.memo(Footer);